import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import { Link } from "gatsby";

import { MDBContainer, MDBRow, MDBCol, /*MDBBtn*/ } from "mdbreact";

//import imgPlataformaA from "../../assets/images/a-escola/bem-vindo/plataformaA.png";
//import imgNovidades from "../../assets/images/a-escola/bem-vindo/novidades.png";
//import imgParaOAno from "../../assets/images/a-escola/bem-vindo/paraOAno.png";
import SEO from "../../components/SEO/SEO";
import Button from "../../components/Button/Button";

export default function BemVindo() {
	{/*const margin = { marginBottom: "10px" };*/}

	return (
		<Layout>
			<SEO title="Bem-vindo 2024" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<h1 className="pageTitle fgsecondary-gradient py-5">bem-vindo</h1>
					</MDBCol>
				</MDBRow>
				{/* <MDBRow>
					<MDBCol>
						<div className="embed-responsive-16by9 mb-5">
							<iframe
								className="offseted-background border-0"
								width="100%"
								height="480"
								src="https://www.youtube.com/embed/Ag8WJnQnqZQ"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							></iframe>
						</div>
					</MDBCol>
				</MDBRow> */}
				<MDBRow>
					<MDBCol>
						<h3 className="fgsecondary-gradient pb-3">
						está chegando a hora de dar o start nas aulas de 2024
						</h3>
						<p className="text-justify">
							Como foram as férias? Esperamos que tenha descansado e recarregado
							as energias.
						</p>
						<p className="text-justify">
							<span className="fgsecondary-gradient">
								As aulas retornarão no dia{" "}
								<span className="h5">15/02/2024</span>, no Câmpus I.
							</span>
						</p>
						<h4 className="fgsecondary-gradient pb-3">entrada e saída</h4>
						{/* <p className="text-justify">
							Para melhor organização da Escola e para evitar aglomerações
							pedimos que a entrada e a saída dos estudantes ocorram pelos
							seguintes locais:
							<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
								<li>
									<b>Educação Infantil</b>: acesso exclusivo pelo portão ao lado
									da entrada de pedestres, na Av. Maurício Cardoso.
								</li>
								<li>
									<b>1º Ciclo e 2º Ciclo do Ensino Fundamental - Manhã</b>:
									entrada e saída pelo estacionamento, acesso pela Av. Maurício
									Cardoso, esquina em frente ao Kings Kão.
								</li>
								<li>
									<b>1° Ciclo do Ensino Fundamental - Tarde</b>: entrada e saída
									pelo portão do estacionamento, acesso pela Av. Maurício
									Cardoso, esquina em frente ao Kings Kão.
								</li>
								<li>
									<b>2° Ciclo do Ensino Fundamental - Tarde</b>: entrada pelo
									portão principal de acesso a pedestres da Av. Maurício Cardoso
									(recepção) e saída pela rampa de acesso, na esquina em frente
									à praça da Matriz.
								</li>
								<li>
									<b>3° Ciclo e 4° Ciclo do Ensino Fundamental</b>: entrada
									principal de pedestres (Av. Maurício Cardoso, 510).
								</li>
								<li>
									<b>Ensino Médio</b>:
									<ul style={{ listStyleType: "none" }}>
										<li>
											<b>Itinerários Formativos - Manhã</b>: entrada pelo portão
											em frente à Praça da Matriz.
										</li>
										<li>
											<b>Formação Geral Básica</b>: 9h15min, entrada pelo portão
											principal de pedestres (Av. Maurício Cardoso, 510) 12h,
											saída pelo portão em frente à Praça da Matriz (todos).
										</li>
										<li>
											<b>Itinerários Formativos - Tarde</b>: entrada e saída
											pelo acesso principal de pedestres (Av. Maurício Cardoso,
											510).
										</li>
									</ul>
								</li>
							</ul>
							<b>Irmãos de Ciclos diferentes</b> podem optar pelo mesmo portão
							de entrada e saída. Situações especiais, solicitamos conversar com
							o NAP.
						</p> */}
						<p className="text-justify">
							Ao chegar na Escola, os professores e auxiliares de aprendizagem
							farão o encaminhamento dos estudantes para as suas salas de aula
							referência.
							<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
								<li>
									<b>Educação Infantil, 1° e 2° Ciclos do Ensino Fundamental</b>
									: salas no piso térreo.
								</li>
								<li>
									<b>3° e 4° Ciclo</b>: 2° andar.
								</li>
								<li>
									<b>Ensino Médio</b>: 3° andar.
								</li>
							</ul>
						</p>
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol>
						<h4 className="fgsecondary-gradient pb-3">horário de aula</h4>
					</MDBCol>
				</MDBRow>
				<MDBRow className="text-center text-md-left">
					<MDBCol col={12} md={4}>
						<b>Educação Infantil e Ensino Fundamental</b>
						<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
							<li>Manhã: 7h20min às 11h50min</li>
							<li>Tarde: 13h20min às 17h50min</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={4}>
						<b>
							Ensino Médio
							<br /> Formação Geral Básica
						</b>
						<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
							<li>9h15min às 12h</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={4}>
						<b>Itinerários Formativos</b>
						<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
							<li>Manhã: 7h20min às 9h </li>
							<li>Tarde: 13h20min às 17h20min</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<hr />
				<MDBRow>
					<MDBCol col={12} md={6}>
						<h4 className="fgsecondary-gradient pb-3">materiais escolares</h4>
						<p className="text-justify">
							Abaixo, confira os materiais indicados pela Escola para melhor
							organização diária, de acordo com a metodologia, e para
							qualificação da experiência nos projetos.
						</p>
						<p className="text-justify">
							A Escola possui uma livraria dentro de seu espaço físico.
						</p>
						<p className="font-weight-bold text-center text-md-left m-0">
							Livraria Letras e Cultura
						</p>
						<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
							<li>Local: Escola de Aplicação Feevale, sala 114. </li>
							<li>Horário: segunda-feira a sexta-feira, das 9h às 18h.</li>
							<li>
								Telefone: <a href="tel:05135868800">(51) 3586.8800</a>, ramal:
								7153 | <a href="tel:051997959853">(51) 99795.9853</a>.
							</li>
						</ul>
						<h4 className="fgsecondary-gradient pb-3"> ensino infantil </h4>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/8df634d4-9a07-4779-b47e-55c0b2663ca6/01_Escola24_ListaMateriaisEscolar_A4_Ed.Infantil_I.pdf"
								text="Educação Infantil I"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/5683a4c2-de19-41cb-ad3b-3ac8ead8181f/02_Escola24_ListaMateriaisEscolar_A4_Ed.Infantil_II.pdf"
								text="Educação Infantil II"
							/>
						</MDBCol>
						<h4 className="fgsecondary-gradient pb-3"> ensino fundamental </h4>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/f5bdfece-2d12-4b85-8a4f-631cd09f3bad/03_Escola24_ListaMateriaisEscolar_A4_1CicloFundamental.pdf"
								text="1° ciclo"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/18ccb3c9-17f7-4cc3-b8ab-7531e51d88b5/04_Escola24_ListaMateriaisEscolar_A4_2CicloFundamental.pdf"
								text="2° ciclo"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/8b3e9bb0-b5cb-4633-8e3d-2a2c6274e844/05_Escola24_ListaMateriaisEscolar_A4_3CicloFundamental.pdf"
								text="3° ciclo"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/c454566c-f09c-4391-bde4-a4d12bfff489/06_Escola24_ListaMateriaisEscolar_A4_4CicloFundamental.pdf"
								text="4° ciclo"
							/>
						</MDBCol>
						<h4 className="fgsecondary-gradient pb-3"> ensino médio </h4>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/87244952-a769-4638-a8df-19019942934d/07_Escola24_ListaMateriaisEscolar_A4_EnsinoM%c3%a9dio.pdf"
								text="1° e 2° ciclo"
							/>
						</MDBCol>
						<h4 className="fgsecondary-gradient pb-3"> materiais complementares </h4>
						<span>
							<p> Confira abaixo as folhas timbradas. 
								As folhas timbradas com linhas precisam ser impressas de forma 
								frente e verso. </p>
						</span>
						{/*<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/3b403dfd-e3a9-4ef7-9748-6f7cb678c68e/Folha_Timbrada_Escola_2023_Em_branco_1%c2%b0_Ciclo.pdf"
								text="Folha timbrada em branco - 1° ciclo"
							/>
					</MDBCol>*/}
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/49dbf8b1-acbf-4e98-a522-dbf6d73cbd92/Escola23_FolhasTimbradas_ComLinhas_PrimeirosCiclos_A4.pdf"
								text="Folha timbrada com linhas - 1° ciclo"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/0a9b6d61-228d-46e1-bd0c-e084969ae051/Escola23_FolhasTimbradas_ComLinhas_A4.pdf"
								text="Folha timbrada com linhas - A partir 2° ciclo"
							/>
						</MDBCol>
						<MDBCol className="mb-2">
							<Button
								type="institucional"
								to="https://www.feevale.br/s/conteudo/7ff239fa-d676-4f9f-88df-4cdb75498948/Escola23_FolhasTimbradas_EmBranco_A4.pdf"
								text="Folha timbrada em branco"
							/>
						</MDBCol>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h4 className="fgsecondary-gradient pb-3">lanches e almoços</h4>
						<p className="text-justify">
							A Escola possui a Cantina do Kako, que serve lanches e almoços
							para estudantes e famílias que tiverem interesse em comprar. Além
							disso, possuímos dois micro-ondas que estão à disposição dos
							estudantes para esquentar seu lanche e/ou almoço.
						</p>
						<h4 className="fgsecondary-gradient pb-3">
							multiformes (coleção de vestuário e acessórios da Escola de
							Aplicação Feevale){" "}
						</h4>
						{/*<p className="text-justify">
							Na Educação Infantil e no Ensino Fundamental, o uso do multiforme
							é indispensável; no Ensino Médio, é recomendado. Para saída de
							estudos e representação da Escola em eventos, o uso é obrigatório.
						</p>*/}
						<p className="text-justify">
						O uso do multiforme completo é obrigatório para todos os estudantes da Escola.
						</p>
						<p className="font-weight-bold text-center text-md-left m-0">
							Infinity livraria
						</p>
						<ul style={{ listStyleType: "none" }} className="p-0 pl-md-5">
							<li>Local: Escola de Aplicação Feevale, sala 014.</li>
							<li>
								Horário: segunda-feira a sexta-feira, das 9h30min às 13h30min.
							</li>
							<li>
								Telefone: <a href="tel:05135868800">(51) 3586.8800</a>, ramal:
								7153 | <a href="tel:051996796546">(51) 99679.6546</a>.
							</li>
						</ul>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<hr />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6}>
						<img 
						src="https://www.feevale.br/s/conteudo/1b6831c4-7b41-4e24-8cd1-db22b27f7f56/21_Bem-Vindo2024_Escola_PM_BannerSess%c3%a3o_574x244px.png"
						alt="novidades" 
						className="img-fluid pb-2" />
						<ul style={{ listStyleType: "none" }}>
							<li>
								// novo Ensino Médio com muitas opções de Itinerários Formativos
							</li>
							<li>// projetos incríveis</li>
							<l1>// internacionalização</l1>
							<li>// mentoria</li>
							<li>// investigação científica</li>
							<li>// workshops</li>
							<li>// trajetórias customizadas</li>
							<li>
								//{" "}
								<Link to="/a-escola/desafios-e-festivais">
									desafios & festivais
								</Link>
							</li>
						</ul>
					</MDBCol>
					{/* <MDBCol col={12} md={6}>
						<img
							src={imgNovidades}
							alt="novidades"
							className="img-fluid pb-2"
						/>
						<ul style={{ listStyleType: "none" }}>
							<li>// seminários multiculturais</li>
							<li>// trabalho por ciclo ainda mais integrado</li>
							<li>
								// ambiente Virtual de Aprendizagem mais interativo e intuitivo
							</li>
						</ul>
					</MDBCol> */}
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
